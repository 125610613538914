import React from 'react';
import { useLocation } from 'react-router-dom';
import Tienda from '../../components/Tienda';
import InputSearch from '../../components/InputSearch/InputSearch.js';
import './styles.scss';

const TiendaPage = (props) => {
	const location = useLocation();
	return (
		<>
			{
				location && location.pathname.indexOf("checkout") === -1 ? (
					<section className="bgTienda mb-5">
						<div className="container">
							<div className="row">
								<div className="col-12 col-md-6">
									<h2>¿Qué estás buscando?</h2>
									<InputSearch></InputSearch>
								</div>
							</div>
						</div>
					</section>
				) : (
					<section className="py-3" />
				)
			}
			<div className="container">
				<Tienda {...props} />
			</div>
		</>
	);
}

export default TiendaPage;
