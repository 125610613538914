import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Breadcrumb, BreadcrumbItem, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import PropTypes from 'prop-types';
import { BeatLoader } from 'react-spinners';


class ProductosHeader extends Component {

  constructor(props) {
    super(props);
    this.state = {
      orderDropdownShow: false,
      prodsDropdownShow: false,
    }
  }

  static propTypes = {
    breadcrumbs: PropTypes.array,
    loading: PropTypes.bool,
  }

  hoverOrderDropdown(show) {
    this.setState((prevState) => {
      prevState.orderDropdownShow = show;
      return prevState;
    });
  }

  hoverProdsDropdownShow(show) {
    this.setState((prevState) => {
      prevState.prodsDropdownShow = show;
      return prevState;
    });
  }

  updateOrderDropdown(show, sort = null) {
    this.setState((prevState) => {
      prevState.orderDropdownShow = show;
      return prevState;
    }, () => {if(sort != null) this.props.onChangeSearch(sort, 'sort')})
  }

  updatePageSizeDropdown(pageSize) {
    this.props.onChangeSearch(pageSize,'pageSize');
  }

  render() {
    const { orderDropdownShow, prodsDropdownShow, } = this.state;
    const { breadcrumbs, loading,  sort, pageSize } = this.props;
    let isTouchDevice =  "ontouchstart" in document.documentElement;
    let sortContent = <Fragment><i className="fa fa-sort-amount-asc"></i> Ordenar por <i className="fa fa-chevron-down"></i></Fragment>;
    let qtyProducts = <Fragment><i className="fa fa-sort-amount-asc"></i> Productos por páginas <i className="fa fa-chevron-down"></i></Fragment>;

    switch (sort) {
      case 'Precio':
      sortContent = <Fragment><i className="fa fa-sort-amount-asc"></i> Menor Precio <i className="fa fa-chevron-down"></i></Fragment>;
      break;
      case '-Precio':
      sortContent = <Fragment><i className="fa fa-sort-amount-desc"></i> Mayor Precio <i className="fa fa-chevron-down"></i></Fragment>;
      break;
    }

    switch (parseInt(pageSize, 10)) {
      case 12:
      qtyProducts = <Fragment><i className="fa fa-sort-amount-asc"></i> 12 Prod/Página <i className="fa fa-chevron-down"></i></Fragment>;
      break;
      case 24:
      qtyProducts = <Fragment><i className="fa fa-sort-amount-desc"></i> 24 Prod/Página <i className="fa fa-chevron-down"></i></Fragment>;
      break;
      case 48:
      qtyProducts = <Fragment><i className="fa fa-sort-amount-desc"></i> 48 Prod/Página <i className="fa fa-chevron-down"></i></Fragment>;
      break;
      default:
      qtyProducts = <Fragment><i className="fa fa-sort-amount-desc"></i> 64 Prod/Página <i className="fa fa-chevron-down"></i></Fragment>;
    }

    return (
      <header>
        <div className="esige-productos-controls px-0 px-md-4 px-lg-4 px-xl-4 px-md-0 mt-1">
          <Nav>
            <Breadcrumb>
              <BreadcrumbItem><Link to="/">INICIO</Link></BreadcrumbItem>
              {loading ?
                <BreadcrumbItem active={true} className="breadcrumb-loader">
                  <BeatLoader />
                </BreadcrumbItem>
              : breadcrumbs.map((x, i) =>
                <BreadcrumbItem key={`breadcrumbitem-${i}`} active={breadcrumbs.length === i ? true : false}>
                  <Link to={"/tienda/productos/" + x.code + "/" + breadcrumbs.filter((y, j) => j <= i).map(x => x.Nombre).join('-').split(' ').join('-')}>{x.Nombre.toUpperCase()}</Link>
                </BreadcrumbItem>
              )}
            </Breadcrumb>
            <div className="d-inline ml-auto my-auto">
              <Dropdown toggle={() => { }}
                isOpen={orderDropdownShow} nav className="ml-auto d-inline"
                onMouseEnter={!isTouchDevice ? () => this.hoverOrderDropdown(true) : null}
                onMouseLeave={!isTouchDevice ?  () => this.hoverOrderDropdown(false): null}
                onClick={()=>this.hoverOrderDropdown(!orderDropdownShow)}
              >
                <DropdownToggle className="btn-light responsive-button p-1 p-md-2 pb-md-2">{sortContent}</DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={() => this.updateOrderDropdown(false, 'Precio')}>Menor Precio</DropdownItem>
                  <DropdownItem onClick={() => this.updateOrderDropdown(false, '-Precio')}>Mayor Precio</DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <Dropdown toggle={() => { }}
                isOpen={prodsDropdownShow} nav className="ml-1 d-inline"
                onMouseEnter={!isTouchDevice ? () => this.hoverProdsDropdownShow(true): null}
                onMouseLeave={!isTouchDevice ? () => this.hoverProdsDropdownShow(false): null}
                onClick={()=>this.hoverProdsDropdownShow(!prodsDropdownShow)}
              >
                <DropdownToggle className="btn-light responsive-button p-1 p-md-2 pb-md-2">{qtyProducts}</DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={() => this.updatePageSizeDropdown(12)}>12 Prod/Página</DropdownItem>
                  <DropdownItem onClick={() => this.updatePageSizeDropdown(24)}>24 Prod/Página</DropdownItem>
                  <DropdownItem onClick={() => this.updatePageSizeDropdown(48)}>48 Prod/Página</DropdownItem>
                  <DropdownItem onClick={() => this.updatePageSizeDropdown(64)}>64 Prod/Página</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </Nav>
        </div>
      </header>
        );
      }
    }

    export default ProductosHeader;
