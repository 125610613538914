const BASE_URL = process.env.REACT_APP_TIENDA_BASE_URL;
const PUBLIC_TOKEN = process.env.REACT_APP_TIENDA_PUBLIC_TOKEN;

module.exports = {
     BASE_URL: BASE_URL,
     PUBLIC_TOKEN: PUBLIC_TOKEN,
     api: {
          articulos: {
               articulo: BASE_URL + "api/articulos/articulo/",
               articulos: BASE_URL + "api/articulos/",
               colores: BASE_URL + "api/articulos/colores/",
               talles: BASE_URL + "api/articulos/talles/",
               random: BASE_URL + "api/articulos/random/",
               search: BASE_URL + "api/articulos/search/",
               solicitud: BASE_URL + "api/solicitud/articulo/",
               destacados: BASE_URL + "api/articulos/destacados/",
               categorias: BASE_URL + "api/articulos/categorias/",
               grupos: BASE_URL + "api/articulos/grupos/",

               ofertas: {
                    ofertasAllRandom: BASE_URL + "api/articulos/ofertas/all/random/",
                    ofertasDefinidasPorUsuarioRandom: BASE_URL + "api/articulos/ofertas/random/",
                    ofertas: BASE_URL + "api/articulos/ofertas/",
                    todasLasOfertas: BASE_URL + "api/articulos/ofertas/all/",
               }
          },
          sitio: {
               contacto: BASE_URL + "api/contacto/",
               menu: BASE_URL + "api/menu/",
               carrusel: BASE_URL + "api/carrusel/",
               novedades: BASE_URL + "sitio/novedades/last/",
          },
          clientes: {
               auth: {
                    sigletonToken: BASE_URL + "auth/generateSingletonToken/",
                    login: BASE_URL + "auth/login/",
                    logoff: BASE_URL + "auth/logout/",
                    check: BASE_URL + "auth/check/",
                    regenerarPassword: BASE_URL + "auth/regenerarPassword/",
                    register: BASE_URL + "auth/register/",
               },
               cliente: {
                    alta: BASE_URL + "api/clientes/alta/",
                    pedidos: BASE_URL + "api/clientes/cliente/pedidos/",
                    descargarFicheroListaPrecio: BASE_URL + "api/precios/listadeprecios/downloadFichero/",
                    resumenCuenta: BASE_URL + "api/cuentascorrientes/movimientos/",
                    perfil: BASE_URL + "api/clientes/cliente/perfil/",

                    recentqueries: BASE_URL + "auth/recentqueries/",
                    carrito: BASE_URL + "api/clientes/cliente/carrito/",
                    transportesAsociados: BASE_URL + "api/clientes/cliente/transportes/",

                    tiposdocumentos: BASE_URL + "api/generales/tiposdocumentos/",
                    localidades: BASE_URL + "api/generales/localidades/",
                    situacionesIVA: BASE_URL + "api/generales/situacionesiva/",
               },
          },
          ventas: {
               pedidosGenerar: BASE_URL + "api/facturador/pedidos/generar/",
               condicionesdepago: BASE_URL + "api/facturador/condicionesdepago/",
               tarjetasyplanes: BASE_URL + "api/facturador/tarjetasyplanes/",
               transportes: BASE_URL + "api/facturador/transportes/",
               cart: BASE_URL + "api/facturador/carrito/",

               mercadopago: {
                    generarPreferencia: BASE_URL + "api/facturador/mp/preferencia/generar/",
                    obtenerPreferencia: BASE_URL + "api/facturador/mp/preferencia/obtener/",
                    procesarPreferencia: BASE_URL + "api/facturador/mp/preferencia/response/",
               }
          },
     }
}
