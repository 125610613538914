import React, { Component } from 'react';
import {Input} from 'reactstrap';

import api from "../../../api/api.js";
import apifunctions from "../../../api/functions.js";


class TransportesSelector extends Component{
  constructor(props){
    super(props);
    this.state={
      transportes:[],
    };
  }

  componentDidMount(){
    let successCallback = (response) => {
      let items = response.data.map(item=>({transporte:item.Transporte, checked: item.Defecto, id:item.Codigo}));
      let checkedItems = items.filter(item=>item.checked === true);
      if(items.length > 0 && checkedItems.length === 0){
        items[0].checked = true;
      }
      this.setState(
        {transportes:items},
        ()=>{
          let checkedItem = items.filter(item=>item.checked === true);
          if(checkedItem.length > 0 && this.props.onChange){
            this.props.onChange(checkedItem[0].id);
          }
        }
      );
    };
    let failCallback = () => {
      this.setState(
        {transportes:[]},
        ()=>{if(this.props.onChange) this.props.onChange(0);}
      );
    };
    apifunctions.get(api.api.ventas.transportes, null, null, null, successCallback, failCallback, failCallback);
  }

  selectTransporte(transporteID){
    this.setState(
      prevState => {
        let list = prevState.transportes;
        list.forEach(
          item => {
            item.checked = false;
            if(item.id === transporteID){
              item.checked = true;
            }
          }
        );
        prevState.transportes = list;
        return prevState;
      },
      () => {if(this.props.onChange)this.props.onChange(transporteID);}
    );
  }

  render(){
    const transportes = this.state.transportes;
    return (
      transportes.length > 0 ?
      <React.Fragment>
        <h4 className="color">Transporte</h4>
        <Input type="select" name="select" id="exampleSelect"
          onChange={(opt)=>this.selectTransporte(opt.target.value)}
        >
          {
            transportes.map(
              (item,index) =>
                <option key={"transportes-"+String(index)} id={"transportes-"+String(index)}
                  value={item.id}
                  selected={item.checked}
                >
                  {item.transporte}
                </option>
            )
          }
        </Input>
      </React.Fragment>
          :
          <h5 className="color">No posee transportes asociados</h5>
        );
      }
    }
    export default TransportesSelector;
