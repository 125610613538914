import React, { Component } from 'react';
import PropTypes from 'prop-types';

import functions from "../../api/functions.js";
import api from "../../api/api.js";

import { withRouter } from "react-router";

export const LoginContext = React.createContext();

class LoginProvider extends Component {
	constructor(props) {
		super(props);
		this.state = {
			displayname: '',
			token: null,
			loading: false,
			logged: !!localStorage.getItem('token'),
			errorMsg: "",
		}
	}

	static propTypes = {
		children: PropTypes.any,
	}

	componentDidMount() {
		this.load((result) => { if (result) this.isLogged(); });
	}

	decode() {
		let token = localStorage.getItem('token');
		if (token) {
			return atob(token);
		} else {
			return false;
		}
	}

	load(callback) {
		let decoded = this.decode();
		if ((decoded) && (this.ValidToken(decoded))) {
			this.setState({ token: JSON.parse(decoded), logged: true, errorMsg: "", displayname: '', }, () => {
				if (callback) callback(true);
			});
		} else {
			localStorage.removeItem('token');
			this.setState({ token: "", logged: false, errorMsg: "", displayname: '', });
		}

		if (callback) callback(false);
	}

	encode() {
		const { token } = this.state;
		return btoa(JSON.stringify(token));
	}

	save() {
		localStorage.setItem('token', this.encode());
	}

	isLogged(callback) {
		const { token } = this.state;
		functions.post(api.api.clientes.auth.check, null, { token: token, public_token: api.PUBLIC_TOKEN }, (data) => {
			if (data.meta.allowed === true && data.data !== undefined && data.data.exists === true) {
				this.setState({ logged: true, displayname: data.data.displayname });
				if (callback) callback(true);
			} else {
				this.setState({ logged: false, displayname: '' });
				localStorage.removeItem('token');
				localStorage.removeItem('displayname');
				if (callback) callback(false);
			}
		}, () => {
			this.setState({ logged: false, displayname: '' });
			localStorage.removeItem('token');
			localStorage.removeItem('displayname');
			if (callback) callback(false);
		})
	}

	ValidToken(token) {
		return (
			token !== undefined &&
			token !== null &&
			token !== btoa("") &&
			token !== btoa(undefined) &&
			token !== btoa(null) &&
			token.length >= 40
		);
	}

	setToken(data, callback) {
		this.setState({
			token: data.token,
			displayname: data.displayname,
			logged: data.token != null ? true : false,
			errorMsg: data.errorMsg,
		},
			() => this.save(),
			() => callback && callback()
		);
	}

	ValidCartItems(cartItems) {
		return (
			cartItems !== undefined &&
			cartItems !== null &&
			cartItems !== btoa("") &&
			cartItems !== btoa(undefined) &&
			cartItems !== btoa(null)
		);
	}

	setCartItems(cartItems) {
		const DecodeCart = (hash) => {
			if (hash) {
				let result = JSON.parse(atob(hash));
				result = Array.isArray(result) ? result.map(item => ({ code: item.code, quantity: item.quantity, color_id: item.color_id, talle_id: item.talle_id })) : [];
				result.forEach(item => {
					item.talle_id = (!!!item.talle_id) ? null : item.talle_id;
					item.color_id = (!!!item.color_id) ? null : item.color_id;
				});
				return result;
			}
			return [];
		};
		const EncodeCart = (cart) => btoa(JSON.stringify(cart));

		try {
			const StoredCart = DecodeCart(localStorage.getItem('cartItems'));
			const MostRecentCart = DecodeCart(cartItems);
			const Cart = [];
			StoredCart.forEach(item => {
				Cart.push(item);
			});
			MostRecentCart.forEach(item => {
				if (StoredCart.filter(itm => itm.code == item.code).length == 0) {
					Cart.push(item);
				}
			});
			localStorage.setItem("cartItems", EncodeCart(Cart));
		}
		catch { }
	}

	login(username, password, callback) {
		functions.post(api.api.clientes.auth.login, null,
			{
				username: username,
				password: password
			},
			(data) => {
				if (data.meta.allowed && this.ValidToken(data.data.token)) {
					this.setCartItems(data.data.cartItems);
					localStorage.setItem('displayname', username);
					this.setToken({ token: data.data.token, displayname: data.data.displayname, errorMsg: '', });
					callback(true);
				} else {
					if (data.errors !== undefined && data.errors.error !== undefined) {
						this.setToken({ token: null, displayname: '', errorMsg: data.errors.error });
					}
					else if (data.detail !== undefined) {
						this.setToken({ token: null, displayname: '', errorMsg: data.detail });
					}
					else {
						this.setToken({ token: null, displayname: '', errorMsg: '' });
					}
					localStorage.setItem('displayname', '');
					localStorage.setItem('cartItems', 'W10=');
					callback(false);
				}
				this.props.history && this.props.history.go(0);
			}, (data) => {
				if (data.errors !== undefined && data.errors.error !== undefined) {
					this.setToken({ token: null, displayname: '', errorMsg: data.errors.error });
				}
				else if (data.detail !== undefined) {
					this.setToken({ token: null, displayname: '', errorMsg: data.detail });
				}
				else {
					this.setToken({ token: null, displayname: '', errorMsg: '' });
				}
				localStorage.setItem('displayname', '');
				localStorage.setItem('cartItems', '');
				callback(false);
			}
		);
	}

	logout(callback) {
		let cartItems = localStorage.getItem('cartItems');
		this.props.history && this.props.history.push("/");

		let clean = (data) => {
			localStorage.removeItem('token');
			localStorage.removeItem('displayname');
			localStorage.removeItem('cartItems');

			this.setState(
				{ token: "", logged: false, errorMsg: "", displayname: '' },
				() => { if (callback) callback() }
			);
		}

		functions.post(api.api.clientes.auth.logoff, null,
			{
				cartItems: cartItems !== undefined && cartItems !== null ? cartItems : 'W10=',
			},
			clean, clean
		);
	}

	render() {
		const { token, loading, logged, errorMsg, displayname } = this.state;
		const { children } = this.props;
		return (
			<LoginContext.Provider value={{
				token: token,
				displayname: displayname,
				login: (username, password, callback) => this.login(username, password, callback),
				isLogged: (callback) => this.isLogged(callback),
				logout: (callback) => this.logout(callback),
				logged: logged,
				loading: loading,
				errorMsg: errorMsg
			}}>
				{children}
			</LoginContext.Provider >
		)
	}
}

export default withRouter(LoginProvider);
