import React, { useContext, useEffect, useState } from "react";

import { CartContext } from "../../components/ParadigmaCartProvider";
import { View, LoadingScreenProps } from "../../../TiendaConfig";
import LoadingScreen from 'react-loading-screen';

import CartEmpty from "./CartEmpty.js";
import CartDetailed from "./CartDetailed/CartDetailed.js";


const Checkout = (props) => {

  const [successFulOrder, setSuccessfulOrder] = useState(false);

  const cartContext = useContext(CartContext);

  const updateURL = (url) => {
      props.history && props.history.push(url)
  }

  useEffect(() => () => {
    cartContext.setCodigoCondicionDePago(null, null);
  }, []);

  return (
    cartContext.cartState.articulos.length > 0 ?
      <CartDetailed {...cartContext} {...props} onSuccessfulOrder={(p) => setSuccessfulOrder(p)} /> :
      <CartEmpty successfulOrder={successFulOrder} updateURL={updateURL} />
  )
}

export default Checkout;