import React, { useEffect, useState } from 'react';
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption } from 'reactstrap';
import apifunctions from '../Tienda/api/functions';
import { api } from '../Tienda/api';

const CarouselHome = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [items, setItems] = useState([]);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  useEffect(() => {
    const getAndProcess = (reintento = 0) => {
      const successCallback = (response) => setItems(response ? response.sort((a, b) => (a.Order - b.Order)) : []);
      const failureCallback = () => {
        if (reintento < 3) getAndProcess(reintento + 1);
        else setItems([]);
      }
      apifunctions.get(api.sitio.carrusel, null, null, {}, successCallback, failureCallback, failureCallback);
    }
    getAndProcess();
  }, [])

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={`Imagen-Carrusel-${item.Order}`}
      >
        <img
          src={item.Imagen}
          alt={item.Titulo ? item.Titulo : `Imagen Carrusel ${item.Order}`}
          width={item.Width} height={item.Height}
        />
        {
          (item.Titulo || item.Subtitulo) &&
          <CarouselCaption captionText={item.Subtitulo} captionHeader={item.Titulo} />
        }
      </CarouselItem>
    );
  });

  return (
    <Carousel
      className="carousel__home"
      activeIndex={activeIndex}
      next={next}
      previous={previous}
    >
      <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
      {slides}
      <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
    </Carousel>
  );
}

export default CarouselHome
