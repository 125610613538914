import React, { Fragment, useState, useEffect, useContext } from 'react';
import { Button, } from 'reactstrap';
import { withRouter } from "react-router";

import { CartContext } from '../ParadigmaCartProvider';
import { LoginContext } from '../ParadigmaLoginProvider';

import { CartToolTip } from './CartDetail.js';
import { animate, ClickInLogin, RedirectToCheckout, onClickEventHandler } from './CheckoutButtonUtils';

const FloatButton = (props) =>
	<button
		{...props}
		className="btn checkout-floating-btn rounded-circle p-0 "
	>
		<span className="cart__button cursor-pointer menu-usuario-icon">
			<svg className="cart__svg" fill="white" id="floating-cart-icon" enableBackground="new 0 0 511.343 511.343" height="512" viewBox="0 0 511.343 511.343" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m490.334 106.668h-399.808l-5.943-66.207c-.972-10.827-10.046-19.123-20.916-19.123h-42.667c-11.598 0-21 9.402-21 21s9.402 21 21 21h23.468c12.825 142.882-20.321-226.415 24.153 269.089 1.714 19.394 12.193 40.439 30.245 54.739-32.547 41.564-2.809 102.839 50.134 102.839 43.942 0 74.935-43.826 59.866-85.334h114.936c-15.05 41.455 15.876 85.334 59.866 85.334 35.106 0 63.667-28.561 63.667-63.667s-28.561-63.667-63.667-63.667h-234.526c-15.952 0-29.853-9.624-35.853-23.646l335.608-19.724c9.162-.538 16.914-6.966 19.141-15.87l42.67-170.67c3.308-13.234-6.71-26.093-20.374-26.093zm-341.334 341.337c-11.946 0-21.666-9.72-21.666-21.667s9.72-21.667 21.666-21.667c11.947 0 21.667 9.72 21.667 21.667s-9.72 21.667-21.667 21.667zm234.667 0c-11.947 0-21.667-9.72-21.667-21.667s9.72-21.667 21.667-21.667 21.667 9.72 21.667 21.667-9.72 21.667-21.667 21.667zm47.366-169.726-323.397 19.005-13.34-148.617h369.142z" /></svg>
			<span className="cart__number">
				{props.cantItems}
			</span>
		</span>
	</button>

const FloatCheckoutButton = (props) => {

	const [showDetails, setShowDetails] = useState(false);

	const logged = useContext(LoginContext).logged;
	const cartContext = useContext(CartContext);

	useEffect(() => animate("floating-cart-icon"), [cartContext.cartState.cantidadTotal]);

	const isTouchDevice = "ontouchstart" in document.documentElement;

	return (
		<>
			<FloatButton
				onClick={() => logged ? RedirectToCheckout(props.history) : ClickInLogin()}
				id="floating-cart-icon-container"
				cantItems={cartContext.cartState.cantidadTotal}
			/>
			{
				!isTouchDevice &&
				<CartToolTip
					onClickCheckoutButton={
						onClickEventHandler(() => logged ? RedirectToCheckout(props.history) : ClickInLogin())
					}
					placement="top"
					isOpen={showDetails}
					target={"floating-cart-icon-container"}
					onChange={(s) => setShowDetails(s)}
					className={"floatingbutton__cart__details"}
				/>
			}

		</>
	)
};

export default withRouter(FloatCheckoutButton);
