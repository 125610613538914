import React, { useState } from "react";
import { Link } from 'react-router-dom';

import {
  Carousel, CarouselItem, CarouselControl, CarouselIndicators, NavLink
} from "reactstrap";

export const ImagesCarousel = (props) => {

  const { articulo, ofertaPeriodo, ofertaCantidad, superoferta } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (!animating) {
      let nextIndex = activeIndex + 1;
      if (activeIndex === (articulo.Imagenes.length - 1))
        nextIndex = 0;
      setActiveIndex(nextIndex);
    }
  }
  const previous = () => {
    if (!animating) {
      let nextIndex = activeIndex - 1;
      if (activeIndex === 0)
        nextIndex = articulo.Imagenes.length - 1;
      setActiveIndex(nextIndex);
    }
  }

  const goToIndex = (newIndex) => {
    if (!animating) {
      setActiveIndex(newIndex);
    }
  }

  return (
    <Carousel
      activeIndex={activeIndex}
      next={() => null}
      previous={() => null}
      autoPlay={false}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <CarouselIndicators
        className="d-none"
        items={articulo.Imagenes.map((x, i) => ({ src: x, key: i }))}
        activeIndex={activeIndex}
        onClickHandler={newIndex => goToIndex(newIndex)}
      />
      {
        articulo.Imagenes.map((y, i) => (
          <CarouselItem
            draggable={activeIndex === i + 1}
            key={`carousel-item-${i}`}
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
            className="adn-producto-box__figure"
          >
            <Link
              to={`/tienda/producto/${articulo.code}/`}
              className="nav-link adn-producto-box__masinfolink p-0 text-dark cursor-pointer"
            >
              <img
                alt={articulo.Titulo ? articulo.Titulo.toUpperCase() : ""}
                src={articulo.Imagenes[0].src}
                className="adn-producto-box__img"
              />
            </Link>
            {
              (ofertaPeriodo || ofertaCantidad || superoferta) && (
                <span className="adn-producto-box__off">
                  {
                    ofertaPeriodo && !superoferta && (
                      <div className="adn-producto-box__oferta">Oferta</div>
                    )
                  }
                  {
                    ofertaCantidad && !superoferta && (
                      <div className="adn-producto-box__oferta-cantidad">
                        Descuento
                        <br />
                        por cantidad
                      </div>
                    )
                  }
                  {
                    !ofertaPeriodo && !ofertaCantidad && superoferta && (
                      <div className="adn-producto-box__oferta">
                        {articulo.DescuentoSuperOferta}%<br />
                        OFF
                      </div>
                    )
                  }
                </span>
              )
            }
          </CarouselItem>
        ))
      }
      {
        articulo.Imagenes.length > 1 && (
          <>
            <CarouselControl
              direction="prev"
              directionText="Anterior"
              onClickHandler={() => previous()}
            />
            <CarouselControl
              direction="next"
              directionText="Siguiente"
              onClickHandler={() => next()}
            />
          </>
        )
      }
    </Carousel>
  );
}

export const OfertaLabel = (props) => {
  const {
    ofertaPeriodo, ofertaCantidad, superoferta,
    DescuentoSuperOferta
  } = props;
  return (
    (ofertaPeriodo || ofertaCantidad || superoferta) && (
      <span className="adn-producto-box__off">
        {
          ofertaPeriodo && !superoferta && (
            <div className="adn-producto-box__oferta">Oferta</div>
          )
        }
        {
          ofertaCantidad && !superoferta && (
            <div className="adn-producto-box__oferta-cantidad">
              Descuento
              <br />
              por cantidad
            </div>
          )
        }
        {
          !ofertaPeriodo && !ofertaCantidad && superoferta && (
            <div className="adn-producto-box__oferta">
              {DescuentoSuperOferta}%<br />
              OFF
            </div>
          )
        }
      </span>
    )
  );
}
