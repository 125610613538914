import React, { useState, useEffect, Component } from 'react';
import api from "../../api/api.js";
import apifunctions from "../../api/functions.js";
import imagenNoDisponible from '../../assets/image_not_found.png';

import { View } from "../../../TiendaConfig";

const OfertasController = (props) => {
  const [ofertas, setOfertas] = useState([]);
  const { title, updateURL } = props;

  useEffect(() => {
    const getAndProcess = (reintento = 0) => {
      const successCallback = (response) => {
        let array = [];
        try {
          let data = response.data;
          if (data != null && data.Ofertas && data.Ofertas.length > 0) array = data.Ofertas;
          array.forEach((item) => {
            if (item.Articulos) item.Articulos.forEach(art => {
              if (art.Imagenes.length === 0) art.Imagenes.push({ src: imagenNoDisponible, talle_id: null, color_id: null });
            })
          });
        } catch (e) {
          array = [];
        }
        setOfertas(array);
      }
      const failureCallback = () => {
        if (reintento > 3)
          setOfertas([]);
        else
          getAndProcess(reintento + 1);
      }
      apifunctions.get(
        api.api.articulos.ofertas.ofertasDefinidasPorUsuarioRandom,
        null,
        1,
        { filters: ['quantity=8'] },
        successCallback,
        failureCallback,
        failureCallback
      );
    };
    getAndProcess();
  }, []);

  return ofertas && ofertas.length > 0 && (
    <View.OfertasView
      title={title}
      ofertas={ofertas}
      updateURL={updateURL}
    />
  );
}

export default OfertasController;