import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import CartProvider from './components/Tienda/components/ParadigmaCartProvider';
import LoginProvider from './components/Tienda/components/ParadigmaLoginProvider';

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

import Tienda from './components/Tienda';
import Home from './views/Home/Home';
import TiendaPage from './views/Tienda/Tienda';

import 'font-awesome/css/font-awesome.min.css';
import './index.scss';
import ContactoPage from './views/Contacto/Contacto';



ReactDOM.render(
  <React.StrictMode>
    <div className="App">
      <Router>
        <LoginProvider>
          <CartProvider>
            <Header />
            <div className="body-page">
              <Switch>
                <Route path="/tienda/" render={(props) => (<TiendaPage {...props} />)} />
                <Route path="/contacto/" render={(props) => (<ContactoPage />)} />
                <Route path="/" exact={true} render={(props) => (<Home {...props} />)} />
                <Redirect to="/" />
              </Switch>
            </div>
            <Footer />
          </CartProvider>
        </LoginProvider>
      </Router>
    </div>
  </React.StrictMode>,
  document.getElementById('root')
);
