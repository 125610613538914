import React from 'react';

import Related from './Related.js';
import ProductoHeader from "./ProductoHeader";
import ProductoBody from './ProductoBody.js';
import { View } from '../../../TiendaConfig';

const ProductoView = ({ loading, breadcrumbs, producto, history, magnifier }) => (
  loading ? (
    <View.LoadingScreen loading={loading} />
  ) : (
    <div className="esige-producto">
      {
        (!!producto) &&
        <>
          <ProductoHeader loading={loading} breadcrumbs={breadcrumbs} />
          <ProductoBody
            producto={producto}
            loading={loading}
            magnifier={(magnifier !== undefined && magnifier !== null) ? magnifier : true}
            goBack={(e) => {
              e.preventDefault();
              history && history.goBack();
            }}
            hasHistory={history && history.length === 1}
          />
          <Related
            ProductosEquivalentes={!loading && producto != undefined && producto.equivalentes != undefined && producto.equivalentes.length > 0 ? producto.equivalentes : []}
            ProductosRelacionados={!loading && producto != undefined && producto.related != undefined && producto.related.length > 0 ? producto.related : []}
          />
        </>
      }
    </div>
  )
);

export default ProductoView;