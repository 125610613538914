import React, { useState, createRef } from 'react'
import { Link } from 'react-router-dom';
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import './InputSearch.scss';

const InputSearch = () => {
    const [search, setSearch] = useState('');

    const searchUpdated = (evt) => {
        if (evt.key !== "Enter") {
            const newState = evt.target.value;
            setSearch(newState);
        }
    }

    const navigateToProductos = (navigate) => {
        if (navigate) {
            let searchBtn = document.getElementById("searchBtn");
            if (searchBtn && searchBtn.click) searchBtn.click();
        }
    }
    return (
        <InputGroup>

            <Link id="searchBtn" to={search !== "" ? `/tienda/productos/?search=${search}` : "/tienda/productos/"} />
            <Input className="border-right-0" type="text" value={search} placeholder="¿Esencias? ¿Colorantes? ¿Utensilios para hornear? ..." onChange={searchUpdated} onKeyDown={(evt) => navigateToProductos(evt.key === "Enter")} />
            {search.length > 0 && <span onClick={(e)=>{
                e.preventDefault();
                setSearch('');
            }} className="fa bg-white border-top border-bottom fa-times pt-2 pr-2 cursor-pointer"></span>}
            <InputGroupAddon addonType="append" onClick={() => navigateToProductos(true)}>
                <InputGroupText className="btn btn-info" >
                    <i className="fa fa-search" aria-hidden="true"></i>
                </InputGroupText>
            </InputGroupAddon>
        </InputGroup>
    );
}
export default InputSearch
