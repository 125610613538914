import React, { useState, Component, Fragment, useContext, useEffect } from 'react';
import { Alert, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import ProductoImages from "./ProductoImages";
import functions from "../../functions/functions.js";

import { CartContext } from "../../components/ParadigmaCartProvider";
import CantidadSelectorController from "../../components/CantidadSelector/CantidadSelectorController";

import { GlobalConfigTienda, View } from '../../../TiendaConfig';
import superOferta from "../../assets/SuperOferta.png";
import imagenNoDisponible from '../../assets/image_not_found.png';
import { useLocation } from 'react-router-dom';

const GoBackBtn = (props) => {
  return (
    <div className={props.hasHistory ? "btn-arrow-left d-none" : "btn-arrow-left"} onClick={(evt) => props.goBack(evt)}>
      <svg width="125" height="40" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <filter id="shadow">
            <feDropShadow dx="3" dy="3" stdDeviation="2" flood-color="#000000" flood-opacity="0.25">
            </feDropShadow>
          </filter>
        </defs>
        <g style={{ filter: 'url(#shadow)' }}>
          <path d="M125,0 L25,0 L0,18 L25,36 L125,36 L100,18 L125,0z" fill="#555"></path>
          <text x="35" y="24" fill="white">VOLVER</text>
        </g>
      </svg>
    </div>
  );
}

const ProductoDescripcion = (props) => {
  const { text } = props;
  return text !== undefined && text !== null && text !== "" && (
    functions.isHTML(text) ? (
      <div dangerouslySetInnerHTML={{ __html: text }} />
    ) : (
      <ul className="list-unstyled my-4">
        {text.split('\n').map((x, i) => <li key={`line-${i}`}>{x}</li>)}
      </ul>
    )
  );
}

const ProductoCaracteristicas = (props) => {
  const { propiedades } = props;
  return propiedades !== undefined && propiedades !== null && propiedades.length > 0 && (
    <div className="esige-producto-detail mt-4">
      {
        propiedades.map((item, index) => (
          <React.Fragment key={`ProductoCaracteristicas-${index}`}>
            <span>{item.Propiedad}</span>
            <span>
              {
                item.Valor === '' || item.Valor === 'No Disponible' ? '———' : item.Valor
              }
            </span>
          </React.Fragment>
        ))
      }
    </div>
  );
}

const ProductoDetail = (props) => {
  const { producto, loading } = props;
  return (
    <div className="esige-producto-title">
      {
        !loading &&
        <Fragment>
          <h1>{producto.Titulo}</h1>
          <ProductoCaracteristicas propiedades={producto.Propiedades} />
          <ProductoDescripcion text={producto.Info} />
          <ProductoDescripcion text={producto.Descripcion} />
        </Fragment>
      }
    </div>
  );
}

const PreferenciaSelector = ({ name, label, opciones, onChange, value }) => {
  let indexOfValue = -1;
  opciones.forEach((op, idx) => {
    indexOfValue = op.id == value ? idx : indexOfValue;
  });
  const render = opciones.filter(item => item.id == null).length != opciones.length;
  return render && (
    <FormGroup>
      <Label for={name}>{label}</Label>
      <Input type="select" name={name} id={name} value={indexOfValue} onChange={(evt) => onChange(opciones[evt.target.value].id)}>
        {
          opciones.map((item, idx) => (
            <option key={`option-${idx}-${name}`} value={idx}>{item.label}</option>
          ))
        }
      </Input>
    </FormGroup>
  );
}

// const generarArraySet = (stock, id, value) => {
//   let result = [];
//   stock.forEach(item => {
//     let esta = result.filter(elem => elem.id == item[id]).length > 0;
//     if (!esta) {
//       result.push({ id: item[id], label: item[id] ? item[value] : "No Especificado" });
//     }
//   })
//   return result.sort((a, b) => a.label < b.label ? -1 : a.label > b.label ? 1 : 0);
// }

const generarArraySet = (items) => {
  let result = [];
  items.forEach(item => {
    let esta = result.filter(elem => elem.Codigo == item.Codigo).length > 0;
    if (!esta) {
      result.push({ id: item.Codigo, label: item.Codigo ? item.Nombre : "No Especificado" });
    }
  })
  return result.sort((a, b) => a.label < b.label ? -1 : a.label > b.label ? 1 : 0);
}

const TalleColorSelector = ({ producto, talle_id, color_id, onChange }) => {
  // const colores = generarArraySet(producto.MovimientosStock, 'Color_id', 'Color');
  // const talles = generarArraySet(producto.MovimientosStock, 'Talle_id', 'Talle');

  const colores = generarArraySet(producto.Colores);
  const talles = generarArraySet(producto.Talles);

  useEffect(() => {
    let c_id = colores.length > 0 ? (colores.filter(c => c.id == color_id).length > 0 ? color_id : colores[0].id) : null;
    let t_id = talles.length > 0 ? (talles.filter(c => c.id == talle_id).length > 0 ? talle_id : talles[0].id) : null;
    setTimeout(() => onChange({ color_id: c_id, talle_id: t_id }), 250);
  }, []);

  return producto.MovimientosStock && producto.MovimientosStock.length > 0 && (
    <Row>
      <Col>
        <PreferenciaSelector
          opciones={talles}
          label="Talles"
          value={talle_id}
          name="tallesSelector"
          onChange={(talle_id) => onChange({ talle_id, color_id })}
        />
      </Col>
      <Col>
        <PreferenciaSelector
          opciones={colores}
          label="Colores"
          value={color_id}
          name="coloresSelector"
          onChange={(color_id) => onChange({ color_id, talle_id })}
        />
      </Col>
    </Row>
  );
}

const useCantidad = ({ code, talle_id, color_id }) => {
  const cartContext = useContext(CartContext);
  const enCarrito = cartContext.getCantidadItem({ code, talle_id, color_id });
  const [cantidad, setCantidad] = useState(enCarrito > 0 ? enCarrito : 1);
  return [cantidad, setCantidad];
}

const ProductoPrecioStockDetail = (props) => {
  const location = useLocation();

  const { producto, loading, cartItem, setPreferencias } = props;
  const { talle_id, color_id } = cartItem;
  const cartContext = useContext(CartContext);

  const [cantidad, setCantidad] = useCantidad(cartItem);

  const enCarrito = cartContext.getCantidadItem(cartItem);
  const stock = functions.getMaxQuantity(producto, talle_id, color_id);

  useEffect(() => {
    const enCarrito = cartContext.getCantidadItem(cartItem);
    setCantidad(enCarrito > 0 ? enCarrito : 1);
  }, [cartContext, talle_id, color_id]);

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const talle_id = search.has("talle_id") ? search.get("talle_id") : null;
    const color_id = search.has("color_id") ? search.get("color_id") : null;
    setPreferencias({ talle_id, color_id });
  }, [location.search]);

  return !loading && producto.Precio > 0 && (
    <Fragment>
      <div className="row">
        <div className="col-12">
          <TalleColorSelector
            producto={producto}
            talle_id={talle_id}
            color_id={color_id}
            onChange={(newValues => setPreferencias(newValues))}
          />
        </div>
        <div className="col-xs-12 col-md-5">
          {
            producto.PuedeComprar &&
            <>
              <p className="qtyTitle">Cantidad:</p>
              <CantidadSelectorController
                cantidadDisponible={stock}
                cantidad={cantidad}
                onChange={(cantidad) => setCantidad(cantidad)}
              />
            </>
          }
        </div>
        <div className="col-xs-12 col-md-7 px-0 px-sm-3">
          <div className="esige-producto-prices">
            <div className="esige-producto-prices-current-price text-center">
              {
                !loading && producto.DescuentoSuperOferta > 0 ? (
                  <div className="mb-0 mt-1">
                    <img src={superOferta} alt="Imagen Oferta" className="" />
                    <h3 className="mb-0 mt-1">{producto.PrecioSuperOferta > 0 ? "$" + functions.FormatPrice(producto.PrecioSuperOferta, GlobalConfigTienda.NumeroDecimales) : ""}</h3>
                    <p><small>Precio de Oferta neto con IVA</small></p>
                    <h3 className="mb-0 mt-1">{producto.PrecioLista > 0 ? "$" + functions.FormatPrice(producto.PrecioLista, GlobalConfigTienda.NumeroDecimales) : ""}</h3>
                    <p><small>Precio de Lista</small></p>
                  </div>
                ) : (
                  <div className="mb-0 mt-1">
                    <h3 className="mb-0 mt-1">{producto.Precio > 0 ? "$" + functions.FormatPrice(producto.Precio, GlobalConfigTienda.NumeroDecimales) : ""}</h3>
                    <p><small>Precio unitario neto con IVA</small></p>
                    <p className="m-0">{producto.PrecioLista > 0 ? "$" + functions.FormatPrice(producto.PrecioLista, GlobalConfigTienda.NumeroDecimales) : ""}</p>
                    <p className="m-0"><small>Precio de Lista</small></p>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
      {
        producto.OfertaPeriodo_FechaDesde != null && producto.OfertaPeriodo_FechaHasta != null &&
        Date.parse(producto.OfertaPeriodo_FechaHasta) >= Date.now() &&
        <Alert color="success text-center">
          {`Oferta vigente desde ${functions.FormatDate(Date.parse(producto.OfertaPeriodo_FechaDesde))} hasta ${functions.FormatDate(Date.parse(producto.OfertaPeriodo_FechaHasta))}`}
        </Alert>
      }
      {
        producto.OfertaPeriodo_FechaDesde != null && producto.OfertaPeriodo_FechaHasta != null && producto.OfertaNxM_Cantidad > 0 &&
        !(Date.parse(producto.OfertaPeriodo_FechaHasta) >= Date.now()) &&
        <Alert color="success text-center">
          {`Llevando ${producto.OfertaNxM_Cantidad} o más unidades: $${functions.FormatPrice(producto.OfertaNxM_Precio, GlobalConfigTienda.NumeroDecimales)}`}
        </Alert>
      }
      <div>
      </div>
      {
        producto.PuedeComprar &&
        <div className="esige-producto-checkout">
          <div className="col-xs-12 col-md-6 pl-0 text-md-right text-center my-1">
            <button
              disabled={!producto.IgnorarStock && stock <= 0}
              onClick={() => cartContext.setItem({ ...cartItem, quantity: cantidad, })}
              className="btn btn-success w-25 rounded"><i className="fa fa-shopping-cart fa-lg fa-2x" aria-hidden="true"></i>
            </button>
          </div>
          <div className="col-xs-12 col-md-6 pl-0 text-md-left text-center my-1">
            <button
              disabled={!enCarrito}
              onClick={() => cartContext.removeItem(cartItem)}
              className="btn btn-danger w-25 rounded"><i className="fa fa-trash fa-lg fa-2x" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      }
    </Fragment>
  );
};

const ProductoBody = (props) => {
  const { producto, loading, goBack, hasHistory, magnifier } = props;

  const [{ talle_id, color_id }, setPreferencias] = useState({ talle_id: null, color_id: null });
  const cartItem = { code: producto.code, talle_id: talle_id, color_id: color_id };

  return (
    <div className="esige-producto-container px-md-4 px-lg-0 mb-2">
      <div className="row esige-producto-main-detail mx-1">
        <ProductoImages
          magnifier={(magnifier !== undefined && magnifier !== null) ? magnifier : true}
          images={loading || (!!!producto) ? [{ src: imagenNoDisponible, talle_id: null, color_id: null }] : producto.Imagenes}
          cartItem={cartItem}
        />
        <div className="col-md-7 col-lg-6 esige-producto-info px-md-0">
          <div className="p-3 p-md-4 p-xl-5">
            <GoBackBtn
              hasHistory={hasHistory}
              goBack={goBack}
            />
            <ProductoDetail
              producto={producto}
              loading={loading}
            />
            <ProductoPrecioStockDetail
              producto={producto}
              loading={loading}
              cartItem={cartItem}
              setPreferencias={setPreferencias}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductoBody;
