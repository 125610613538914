import React, { useState, useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
	Popover,PopoverHeader, 
} from 'reactstrap';

import LoginModal from '../../Tienda/components/LoginNavItem/LoginModal';
import RegisterModal from '../../Tienda/components/LoginNavItem/RegisterModal';
import ForgottenPasswordModal from '../../Tienda/components/LoginNavItem/ForgottenPasswordModal';
import { LoginContext } from '../../Tienda/components/ParadigmaLoginProvider/ParadigmaLoginProvider';
import { CartContext } from '../../Tienda/components/ParadigmaCartProvider/ParadigmaCartProvider';
import '../styles.scss'

const NotLoggedComponent = (props) => {
    const {showLoginModal, setShowLoginModal} = props;
	const [showRegisterModal, setShowRegisterModal] = useState(false);
	const [showForgotPassModal, setShowForgotPassModal] = useState(false);

	return (
		<div className="overflow-auto">
			<LoginModal
				showModal={showLoginModal}
				toggle={() => setShowLoginModal(!!!showLoginModal) & setShowRegisterModal(false) & setShowForgotPassModal(false)}
				openRegisterModal={() => setShowLoginModal(false) & setShowRegisterModal(true) & setShowForgotPassModal(false)}
				openForgotPasswordModal={() => setShowLoginModal(false) & setShowRegisterModal(false) & setShowForgotPassModal(true)}
			/>
			<RegisterModal
				showModal={showRegisterModal}
				toggle={() => setShowRegisterModal(!!!showRegisterModal) & setShowRegisterModal(false)}
				openLoginModal={() => setShowLoginModal(true) & setShowRegisterModal(false)}
			/>
			<ForgottenPasswordModal
				showModal={showForgotPassModal}
				toggle={() => setShowForgotPassModal(!!!showForgotPassModal) & setShowLoginModal(false)}
				openLoginModal={() => setShowLoginModal(true) & setShowForgotPassModal(false)}
			/>
		</div>
	);
}

const DropdownMenuLoggedComponent = ({ logout, classNames, displayname, isOpen, target,toggle }) => {
    
	return (
		<Popover
            className={classNames + " popover-menu-account font-1-25"}
            isOpen={isOpen}
            target = {target}
            placement={'bottom'}
            toggle={toggle}
            trigger="legacy"
		>
            <PopoverHeader>{displayname}</PopoverHeader>
            <NavLink className={'nav-link '} to="/tienda/cliente/perfil" onClick={toggle}>
                <i className="fa fa-user"></i> Editar Datos
            </NavLink>
            <NavLink className={'nav-link '} to="/tienda/cliente/compras" onClick={toggle}>
                <i className="fa fa-cart-arrow-down" aria-hidden="true"></i> Historial de Compras
            </NavLink>
            <NavLink className={'nav-link '} to="/tienda/cliente/resumencuenta" onClick={toggle}>
                <i className="fa fa-credit-card" aria-hidden="true"></i> Estado de Cuenta
            </NavLink>
            <a className={`nav-link cursor-pointer`} onClick={()=>{toggle();logout();}} style={{color:"red"}}>
                <i className="fa fa-sign-out"></i> Salir
            </a>
		</Popover>
	);
}

const MenuLoggedComponent = ({ logout, className, closeHeader}) => (
	<div className={className}>
        <NavLink className={'nav-link '} onClick={closeHeader} to="/tienda/cliente/perfil" >
            <i className="fa fa-user"></i> Editar Datos
        </NavLink>
        <NavLink className={'nav-link '} onClick={closeHeader} to="/tienda/cliente/compras">
            <i className="fa fa-cart-arrow-down" aria-hidden="true"></i> Historial de Compras
        </NavLink>
        <NavLink className={'nav-link '} onClick={closeHeader} to="/tienda/cliente/resumencuenta">
        <i className="fa fa-credit-card" aria-hidden="true"></i> Estado de Cuenta
        </NavLink>
		<a className={`nav-link cursor-pointer`} onClick={() => logout(() => closeHeader())}>
			<i className="fa fa-sign-out"></i> Salir
		</a>
	</div>
);

export const CustomLoginNavItemDesktop = (props) => {

	const { displayname, logout, className, logged}  = props;
    
    const [openMenu, setOpenMenu] = useState(false);
    const toggle = () => setOpenMenu(!openMenu);
    return (
        <>
            <a 
                id="OpenLoginModalBtn" 
                className={"nav-link btn__clientes cursor-pointer " + className}
                onClick={toggle}
            >
            {logged ? 
                <div className="text-center btn-login">
                    <i className="fa fa-user" />
                    <span>Mi Cuenta</span>
                </div>:
                <div className="text-center btn-login">
                    <i className="fa fa-sign-in"/>
                    <span>Ingresar</span>
                </div>
            }
            </a> 
           { 
            logged ?
                <DropdownMenuLoggedComponent
                    logout={logout}
                    className={className ? className : ""}
                    displayname={displayname}
                    isOpen={openMenu}
                    target={"OpenLoginModalBtn"}
                    toggle={toggle}
                />
                :
                <NotLoggedComponent
                    showLoginModal={openMenu}
                    setShowLoginModal={setOpenMenu} 
                />
            }
        </>
    )
}

export const CustomLoginNavItemMobile  = (props) => {

    const {className, logout, logged, closeHeader} = props;
    const [openModal, setOpenModal] = useState(false);

    return (
        !logged ?
        <>
            <a 
                className={"nav-link btn__clientes cursor-pointer " + className }
                onClick={ () => setOpenModal(true)}
            >
                {"Ingresar"}
            </a> 
            <NotLoggedComponent
                showLoginModal={openModal}
                setShowLoginModal={setOpenModal} 
            />
        </>
        :
        <MenuLoggedComponent
            logout={logout}
            className={className ? className : ""}
            closeHeader={closeHeader}
        />
    )
}

const CustomLoginNavItem = (props) =>{

    const {clear} = useContext(CartContext);
    const { logged, logout, displayname } = useContext(LoginContext); 

    return(
        <>
            <CustomLoginNavItemDesktop
                className="d-none d-lg-block"
                logged={logged}
                logout={() => logout(() => clear())}
                displayname={displayname}
            />
            <CustomLoginNavItemMobile
                className="d-block d-lg-none"
                logged={logged}
                logout={() => logout(() => clear())}
                closeHeader={props.closeHeader}
            />
        </>
    )
};

export default CustomLoginNavItem;