import React from 'react';
import { Link } from 'react-router-dom';
import CarouselHome from '../../components/Carousel/Carousel';
import ProductosDestacados from '../../components/Tienda/components/ProdDestacados';
import ProductosOfertados from '../../components/Tienda/components/ProdOfertados';
import UltimasNovedades from '../../components/UltimasNovedades/UltimasNovedades';

import './styles.scss';

// const NavigationBanner = (props) => (
//   <div className="row navigationbanner mx-auto">
//     <div className="col-xs-12 col-md-3 offset-md-3 navigationbanner-ofertas text-center">
//       <h3>Ofertas Únicas</h3>
//       <Link to="/tienda/ofertas" className="btn btn-warning btn-large rounded"><i class="fa fa-percent" aria-hidden="true"></i>{" Ver ofertas"}</Link>
//     </div>
//     <div className="col-xs-12 col-md-3 navigationbanner-productos text-center">
//       <h3>Productos Exclusivos</h3>
//       <Link to="/tienda/productos"  className="btn btn-warning btn-large rounded"><i class="fa fa-shopping-basket" aria-hidden="true"></i>{" Ver productos"}</Link>
//     </div>
//   </div>
// );

const Home = (props) => {
  const updateURL = (url)=>props.history && (document.querySelector("html").scrollTo(0, 0) & props.history.push(url));
  return (
    <div className="home">
      <CarouselHome />
      {/* <NavigationBanner /> */}
      <div className="container mt-5">
        <ProductosDestacados updateURL={updateURL} title="Algunos de Nuestros Productos Destacados" />
        <ProductosOfertados updateURL={updateURL} title="Algunas de nuestras Super Ofertas" />
        <UltimasNovedades />
      </div>
    </div>
  )
}

export default Home;
