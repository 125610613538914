import React from 'react';
import FadeIn from "../../components/Animations/fadeIn.js";
import ProductoBox from "../../components/ProductoBox";
import imagenNoDisponible from '../../assets/image_not_found.png';
import { View } from "../../../TiendaConfig";

const ProductosEquivalentes = (props) => {
  const articulos = props.articulos.sort(() => Math.random() - 0.5).slice(0, 4);
  articulos.forEach((art) => {
    if (art.Imagenes.length === 0) art.Imagenes.push({ src: imagenNoDisponible, talle_id: null, color_id: null, });
  });

  return articulos && articulos.length > 0 && (
    <div className="row esige-producto-related-row mt-5">
      <FadeIn>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-9 esige-productos-list m-auto">
          <h2 className="title mb-4 text-center">Productos Equivalentes</h2>
          <div className="row">
            {
              articulos.map((x, i) => (
                <div className="p-1 col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                  <View.ProductoBox orientation="vertical" key={`producto-box-eq-${i}`} articulo={x} showDetails={true} maxQuantity={999} />
                </div>
              ))
            }
          </div>
        </div>
      </FadeIn>
    </div>
  );

}

const ProductosRelacionados = (props) => {
  const articulos = props.articulos.sort(() => Math.random() - 0.5).slice(0, 4);
  articulos.forEach((art) => {
    if (art.Imagenes.length === 0) art.Imagenes.push({ src: imagenNoDisponible, talle_id: null, color_id: null, });
  });

  return articulos && articulos.length > 0 && (
    <div className="esige-producto-related-row mt-5">
      <FadeIn>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-9 esige-productos-list m-auto">
          <h2 className="title mb-4 text-center">Tal vez te interesen estos productos</h2>
          <div className="row">
            {
              articulos.map((x, i) => (
                <div className="p-1 col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                  <View.ProductoBox orientation="vertical" key={`producto-box-rel-${i}`} articulo={x} showDetails={true} maxQuantity={999} />
                </div>
              ))
            }
          </div>
        </div>
      </FadeIn>
    </div>
  );
}


const Related = (props) => (
  <>
    <ProductosEquivalentes articulos={props.ProductosEquivalentes} />
    <ProductosRelacionados articulos={props.ProductosRelacionados} />
  </>
)

export default Related;
