import React from 'react';

const CantidadSelectorView = (props) => {
	const { Cantidad, setCantidad, className, onChange } = props;
	return (
		<div className={`esige-quantity-select d-flex align-items-center ${className}`}>
			<div className="product-quantity cursor-pointer">
				<span className="cursor-pointer decremento" onClick={() => setCantidad(Cantidad - 1)} />
				<input maxLength="3" type="number" pattern="\d*" value={Cantidad} min="0" max="999" onChange={(evt) => onChange && onChange(evt)} />
				<span className="cursor-pointer incremento" onClick={() => setCantidad(Cantidad + 1)} />
			</div>
		</div >
	);
}

export default CantidadSelectorView;
