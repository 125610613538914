import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import api from '../../components/Tienda/api/api';
import apifunctions from '../../components/Tienda/api/functions';
import functions from '../../components/Tienda/functions/functions';

const NovedadModal = (props) => (
	<Modal centered isOpen={props.show} toggle={props.toggle} className="novedad-modal">
		<ModalHeader toggle={props.toggle}>{props.novedad.Titulo}</ModalHeader>
		<ModalBody>
			<p>
				<img src={props.novedad.URL_Imagen} className="img-fluid" alt={props.novedad.Titulo} />
			</p>
			{
				functions.isHTML(props.novedad.Contenido_HTML) ? (
					<div dangerouslySetInnerHTML={{ __html: props.novedad.Contenido_HTML }} />
				) : (
					<span>{props.novedad.Contenido_HTML}</span>
				)
			}
		</ModalBody>
		<ModalFooter>
			<Button color="secondary" onClick={props.toggle}>Cerrar</Button>
		</ModalFooter>
	</Modal>
);

const Novedad = ({ novedad }) => {
	const [showModal, ShowModal] = useState(false);

	return (
		<div className="col-sm-4 novedad">
			<NovedadModal novedad={novedad} show={showModal} toggle={() => ShowModal(!!!showModal)} />
			<div className="card">
				<img src={novedad.URL_Imagen} className="novedad-img-fluid" alt={novedad.Titulo} />
				<div className="card-body">
					<h5 className="card-title text-center"><strong>{novedad.Titulo}</strong></h5>
					<p className="card-text">
						{
							functions.isHTML(novedad.Contenido_HTML) ? (
								<div dangerouslySetInnerHTML={{ __html: novedad.Contenido_HTML }} />
							) : (
								<span>{novedad.Contenido_HTML}</span>
							)
						}
					</p>
					<div className="novedad-link text-right" title="Ver Más" onClick={() => ShowModal(true)}><i className="cursor-pointer fa fa-plus-square-o" aria-hidden="true"></i></div>
				</div>
				<div className="card-footer text-right"><small>{novedad.FUA}</small></div>
			</div>
		</div>
	);
}

const UltimasNovedades = () => {
	const [novedades, setNovedades] = useState([]);

	useEffect(() => {
		const successCallback = (response) => {
			if (response.data && response.data.length > 0) {
				setNovedades(response.data);
			}
		};
		const failureCallback = () => {
			setNovedades([]);
		}
		apifunctions.get(
			api.api.sitio.novedades, null, null, {},
			successCallback, failureCallback, failureCallback
		);
	}, []);

	return novedades && novedades.length > 0 && (
		<section className="grey last">
			<div className="container">
				<div className="row">
					<div className="col-sm-12 my-4 text-center">
						<h2 className="big my-5">Últimas <strong>Novedades</strong></h2>
					</div>
					{
						novedades.map((novedad, index) => (<Novedad novedad={novedad} key={"novedad-" + index} />))
					}
				</div>
			</div>
		</section>
	);
}

export default UltimasNovedades;
