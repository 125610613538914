import React, { useEffect } from 'react';
import Contacto from '../../components/Contacto/Contacto';

const ContactoPage = () => {

useEffect(() => {
    document.body.classList.add("contactoPage");
}, [])

    return (
        <div className="container">
            <div className="row mt-5">
                <div className="col-12">
                    <Contacto />
                </div>
            </div>
        </div>
            
            
        
    )
}

export default ContactoPage;
