import React, { useState } from 'react';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { Button } from 'reactstrap';

import { View } from "../../../TiendaConfig";

const responsive = {
    theMotherOfLargeDesktop: {
        breakpoint: { max: 3000, min: 1300 },
        items: 5
    },
    superLargeDesktop: {
        breakpoint: { max: 1300, min: 1024 },
        items: 4
    },
    desktop: {
        breakpoint: { max: 1023, min: 768 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 768, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const OfertaView = (props) => {
  const [state, setState] = useState({
    articulos: props.articulos ? props.articulos : [],
    titulo: props.titulo ? props.titulo : "",
    code: props.code ? props.code : "",
  });
  const { updateURL } = props;

  const ButtonGroup = ({ next, previous }) => (
    <div className="carousel-button-group row my-4">
      <div className="col-4 text-center pb-4">
        <button className="btn DestacadosPrevButtonBottom" onClick={() => previous()}><span className="carousel-control-prev-icon" aria-hidden="true"></span></button>
      </div>
      <div className="col-4 text-center pb-4">
        {
          props.updateURL && state.code != "" &&
          <Button className="font-weight-bold destacados-boton-ver-mas" color="success" outline onClick={() => { updateURL && updateURL(`/tienda/ofertas/${state.code}/`) }}>Ver Más</Button>
        }
      </div>
      <div className="col-4 text-center pb-4">
        <button className="btn DestacadosNextButtonBottom" onClick={() => next()}><span className="carousel-control-next-icon" aria-hidden="true"></span></button>
      </div>
    </div>
  );

  return state.articulos != null && state.articulos.length > 0 && (
    <div className="ofertas-row">
      {
        state.titulo != undefined && state.titulo != null && state.titulo.trim().length > 0 ?
          <h3 className="text-center title">
            {state.titulo.toUpperCase()}
          </h3>
          :
          <div className="title-empty mb-2" />
      }
      <div className="esige-productos mb-0">
        <Carousel
          infinite={true}
          autoPlay={false}
          autoPlaySpeed={4000}
          responsive={responsive}
          renderButtonGroupOutside={true}
          customButtonGroup={<ButtonGroup />}
          arrows={false}
          className="esige-productos-list"
        >
          {
            state.articulos.map((art) => (
              <div key={`ofertado-${art.code}`} className="p-1 p-md-2">
                <View.ProductoBox orientation="vertical" maxQuantity={999} articulo={art} />
              </div>
            ))
          }
        </Carousel>
      </div>
    </div>
  );
};

export default OfertaView;