import React,{useState} from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Button, Navbar, NavbarToggler, Collapse, NavbarBrand, Nav, NavItem} from 'reactstrap';
import LoginNavItem from './CustomLoginNavItem/LoginNavItem';
import FloatCheckoutButton from '../Tienda/components/CheckoutButton/FloatCheckoutButton';

import './styles.scss';

import logo from '../../assets/img/LogoWeb.png';

const Header = () => {

  const [isOpen, setIsOpen] = useState(false);

  // const toggle = () => setIsOpen(!isOpen);
  // const close = () => setIsOpen(false);
  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);
	

  return(
    <div className="banner">
      <div className="container">
        <div className="row">

          <Navbar light className="navbar-expand-sm topHeaderNavBar w-100">
					<div className="d-flex d-sm-none w-100">
						<div className="col-9">
                <NavbarBrand href="/" className="mr-auto"><img className="img-fluid" src={logo} alt="Distribuidora La Paz" /></NavbarBrand>
						</div>
						<div className="col-3 d-flex align-items-center">
							<NavbarToggler onClick={toggleNavbar} className="mr-2" />
						</div>
            </div>
            
					<div className="d-none d-sm-block">
						<NavbarBrand href="/" className="mr-auto"><img className="img-fluid" src={logo} alt="Distribuidora La Paz" /></NavbarBrand>
					</div>
					<Collapse isOpen={!collapsed} navbar>
						<Nav navbar className="ml-auto">
							<NavItem>
								<NavLink className="nav-link text-center text-md-left" to="/" exact={true}>INICIO</NavLink>
							</NavItem>
							<NavItem>
								<NavLink className="nav-link text-center text-md-left" to="/tienda/productos">TIENDA ONLINE</NavLink>
							</NavItem>
							<NavItem>
								<NavLink className="nav-link text-center text-md-left" to="/contacto">CONTACTO</NavLink>
							</NavItem>
						</Nav>
						<Nav navbar className="ml-auto">
							<NavItem className="my-auto text-center">
								<LoginNavItem
									text="INICIAR SESIÓN"
									classNamesDesktop="d-none d-md-block"
									classNamesMobile="border-top d-block d-md-none"
								/>
							</NavItem>
						</Nav>
					</Collapse>
				</Navbar>

          {/* <div className="col-6 col-lg-3 d-flex align-items-center">
            <Link className="banner__logo" to="/tienda/">
              <img src={logo} alt="Distribuidora La Paz" />
            </Link>
          </div>

          <div className="d-none d-lg-flex col-lg-6 text-center flex-column justify-content-center">
            <h2>Nuestros Productos</h2>
            <h6>Buscá en nuestro catálogo online lo que estás necesitando.</h6>
          </div>

          <div className="col-6 d-lg-none d-flex align-items-center justify-content-center ">
            <NavbarToggler onClick={toggle} className="button-menu">
              <i class="fa fa-bars"></i>
            </NavbarToggler>
          </div>

          <Navbar className="col-12 col-lg-3" expand="lg">
            <Collapse className="flex-row-reverse font-1-25" isOpen={isOpen} navbar>
              <LoginNavItem closeHeader={close} />
            </Collapse>
          </Navbar> */}

        </div>
        <FloatCheckoutButton />
      </div>
    </div>
  )
};

export default Header;