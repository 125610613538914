import React, { Component } from 'react';
import Animate from 'react-move/Animate';
import { easeQuadIn } from 'd3-ease';

class fadeIn extends Component {
    render() {
        const { children } = this.props;
        return (
            <Animate
                start={{opacity: 0,}}
                enter={[{opacity: [1],timing: { duration: 300, ease: easeQuadIn },},]}
                update={[{timing: { duration: 0, ease: easeQuadIn },},]}
            >
                {(state) => {return (React.cloneElement(children, { style: ({ opacity: state.opacity }) }));}}
            </Animate>);
    }
}

export default fadeIn;
