import React from "react";
import { Link } from 'react-router-dom';
import { Badge, Row, Col } from "reactstrap";
import functions from "../Tienda/functions/functions";

import { ImagesCarousel, OfertaLabel } from './ImagesCarousel';

import { GlobalConfigTienda } from '../TiendaConfig';

function capitalizeFirstLetter(string) {
  let str = string.toLowerCase();
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const PrecioComponent = ({ precio, className }) => (<span className={className ? className : "price"}>$ {functions.FormatPrice(precio, GlobalConfigTienda.NumeroDecimales)}</span>);
const PrecioOfertaComponent = ({ precioBase, precioOferta }) => (
  <>
    <PrecioComponent precio={precioBase} className="priceBanned" />
    <PrecioComponent precio={precioOferta} />
  </>
);

const Precios = ({ articulo, ofertaPeriodo, ofertaCantidad, superoferta }) => {
  const { Precio, OfertaNxM_Precio, PrecioSuperOferta } = articulo;

  return (ofertaPeriodo || ofertaCantidad || superoferta) ? (
    ofertaPeriodo && !superoferta ?
      <PrecioComponent precio={Precio} />
      :
      (
        ofertaCantidad && !superoferta ?
          <PrecioComponent precio={OfertaNxM_Precio} />
          :
          (
            !ofertaPeriodo && !ofertaCantidad && superoferta ?
              <PrecioOfertaComponent precioBase={Precio} precioOferta={PrecioSuperOferta} />
              :
              <PrecioComponent precio={Precio} />
          )
      )
  ) : (
    <PrecioComponent precio={Precio} />
  );
}

const Informacion = (props) => {
  const { articulo } = props;

  return articulo && (
    <>
      <div className="adn-producto-box__title">
        <Link to={`/tienda/producto/${articulo.code}/`} className="nav-link">
          {capitalizeFirstLetter(articulo.Titulo)}
        </Link>
      </div>

      <div className="adn-producto-box__prices">
        {
          articulo.Precio > 0 ? (
            <Precios {...props} />
          ) : (
            ""
          )
        }
      </div>

    </>
  );
}

const ProductoBox = (props) => {
  const { articulo } = props;

  const ofertaPeriodo = (
    articulo.OfertaPeriodo_FechaDesde != null &&
    articulo.OfertaPeriodo_FechaHasta != null &&
    Date.parse(articulo.OfertaPeriodo_FechaHasta) >= Date.now()
  );
  const ofertaCantidad = articulo.OfertaNxM_Cantidad > 0;
  const superoferta = articulo.DescuentoSuperOferta > 0;

  return articulo && (
    <div className="adn-producto-box row m-0">
      <div className="p-0 adn-producto-box__slider my-auto col-12">
        <OfertaLabel
          ofertaPeriodo={ofertaPeriodo}
          ofertaCantidad={ofertaCantidad}
          superoferta={superoferta}
          DescuentoSuperOferta={articulo.DescuentoSuperOferta}
        />
        <ImagesCarousel
          articulo={articulo}
          ofertaPeriodo={ofertaPeriodo}
          ofertaCantidad={ofertaCantidad}
          superoferta={superoferta}
        />
        {/* <Col className="adn-producto-box__categories">
          <Link to={`/tienda/productos/-${articulo.Linea.identifier}${articulo.Linea.code}`} className="nav-link">
            <Badge color="primary">{articulo.Linea.Nombre}</Badge>
          </Link>
          <Link to={`/tienda/productos/-${articulo.Rubro.identifier}${articulo.Rubro.code}`} className="nav-link">
            <Badge color="secondary">{articulo.Rubro.Nombre}</Badge>
          </Link>
        </Col> */}
      </div>
      <div className="adn-producto-box__data col-12">
        <Informacion
          articulo={articulo}
          ofertaPeriodo={ofertaPeriodo}
          ofertaCantidad={ofertaCantidad}
          superoferta={superoferta}
        />
      </div>
    </div>
  );
}
export default ProductoBox;