import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react'; // InfoWindow
import pinMap from '../../assets/img/marker-google-map.png'

import './styles.scss';

let zoom;

if(window.matchMedia("(min-width: 400px)").matches) {
	zoom = 15;
} else {
	zoom = 14;
}


const mapStyles = {
	width: '100%',
	position: 'relative',
	minHeight: '32rem',
};

export class MapContainer extends Component {

	render() {

	  	return (
			<section className="mapa">
				<Map
					google={this.props.google}
					zoom={zoom}
					style={mapStyles}
					styles={[
						{
							featureType: 'all',
							stylers: [{ saturation: -100 }],
						},
					]}
					initialCenter={{ lat: -38.74072654749396, lng: -62.25983219857705 }}  // -38.73772864749396, -62.25983219857705 
					>
					<Marker
						icon= {pinMap}
						position={{ lat: -38.73772864749396, lng: -62.25983219857705 }}
					/>
					{/* <Marker
						icon= {pinMap}
						position={{ lat: -38.731720, lng: -62.282179 }} /> */}
				</Map>
			</section>
	  	);
	}
}

MapContainer.defaultProps = {
	// The style is copy from https://snazzymaps.com/style/2/midnight-commander
	mapStyles: [{"featureType":"all","elementType":"labels.text.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"all","elementType":"labels.text.stroke","stylers":[{"color":"#000000"},{"lightness":13}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#000000"}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#144b53"},{"lightness":14},{"weight":1.4}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#08304b"}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#0c4152"},{"lightness":5}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#000000"}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#0b434f"},{"lightness":25}]},{"featureType":"road.arterial","elementType":"geometry.fill","stylers":[{"color":"#000000"}]},{"featureType":"road.arterial","elementType":"geometry.stroke","stylers":[{"color":"#0b3d51"},{"lightness":16}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#000000"}]},{"featureType":"transit","elementType":"all","stylers":[{"color":"#146474"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#021019"}]}]
}


export default GoogleApiWrapper({
  apiKey: 'AIzaSyCIvwWqr9Kc9I0Smett1GlwLQPtEWASMMM'
})(MapContainer);