import React, { Fragment } from 'react';
import './Footer.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';

import logo from '../../assets/img/LogoWeb.png';
import MapContainer from '../MapContainer/MapContainer';

const Footer = () => {
  return (
    <Fragment>

      <footer>
        <div className="container">
          <div className="row">

            <div className="col-md-6 pe-5 text-center text-md-left">
              <img src={logo} alt="Embalplast Bahía Blanca" />
              <p className="my-3">
                Insumos y materia prima para repostería y panadería.<br />Snacks para eventos. Artículos de cotillón.
              </p>
              

              <div className="row">
                
                <div className="col-md-6">
                  <h4>Dirección</h4>
                  <address>
                    Río Negro 737, Bahía Blanca,<br />
                    Buenos Aires, Argentina
                  </address>
                </div>

                <div className="col-md-6">
                  <h4>Teléfonos:</h4>
                  <p>
                    {/* <FontAwesomeIcon icon={faPhoneAlt} /> (0291) 4555814 <br/>  */}
                    <FontAwesomeIcon icon={faPhoneAlt} /> (0291) 154601642 <br />
                    <FontAwesomeIcon icon={faPhoneAlt} /> 542914601642
                  </p>
                </div>
              </div>
              
            </div>{/* /col-md-6 */}
            
            <div className="col-md-6 mt-5 mt-md-0">
              <MapContainer
                info="Distribuidora La Paz"
              />
            </div>

          </div>

          <div className="row line">
            <div className="col-md-12">
              <p className="text-center">©{new Date().getFullYear().toString()} Diseño y Desarrollo: Paradigma del Sur s.a.</p>
            </div>
          </div>
        </div>
      </footer>

      <a
        data-for="sucursal"
        data-tip="Email"
        data-iscapture="true"
        title="Email"
        href="mailto:ventas@distribuidoralapaz.com.ar?Subject=Consulta%20desde%20la%20web%20DistribuidoraLaPaz.com.ar&body=Hola,%20quisiera%20consultarles%20por%20"
        className="float float_0" target="_blank">
        <i className="fa fa-envelope my-float"></i>
      </a>
      <a
        data-for="sucursal"
        data-tip="Chiclana"
        data-iscapture="true"
        title="Whatsapp de Distribuidora la Paz"
        href="https://api.whatsapp.com/send?phone=5492914603623&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20"
        className="float float_1" target="_blank">
        <i className="fa fa-whatsapp my-float"></i>
      </a>
      <a
        data-for="sucursal"
        data-tip="Chiclana"
        data-iscapture="true"
        title="Whatsapp de Distribuidora la Paz"
        href="https://api.whatsapp.com/send?phone=542914601642&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20"
        className="float float_2" target="_blank">
        <i className="fa fa-whatsapp my-float"></i>
      </a>

    </Fragment>
  );
};

export default Footer;
