import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import React, { Component, Fragment } from 'react';
import {
     Container, Row, Col, Card, CardText,
     CardBody, CardImg, CardTitle, CardSubtitle, Alert,
     TabContent, TabPane, Nav, NavItem, NavLink, Button,
} from 'reactstrap';
// import api from "../../api/api.js";
// import apifunctions from "../../api/functions.js";


class Contacto extends React.Component{
     constructor(props){
          super(props);
          
     //      this.state = {
     //           showToast:false,
     //           disabled:false,
     //           contacto:{
     //                email:'',
     //                nombre:'',
     //                apellido:'',
     //                telefono:'',
     //                mensaje:'',
     //           }
     //      };
     }

     render(){
          const contactanos = (evt) => {
               evt.preventDefault();
               // this.setState(
               //      {disabled:true},
               //      ()=>{
               //           apifunctions.post(
               //                api.api.contacto, null, {data:this.state.contacto},
               //                ()=>this.setState({showToast:true, contacto:{ email:'', nombre:'', apellido:'', telefono:'',mensaje:'',}, disabled:false},()=>setTimeout(()=>this.setState({showToast:false, disabled:false}) ,2500)),
               //                ()=>this.setState({showToast:false, disabled:false}),
               //                ()=>this.setState({showToast:false, disabled:false}),
               //           );
               //      }
               // )
          };
          // const updateContacto = (propName, value) => {
          //      this.setState(prevState=>{
          //           let contacto = prevState.contacto;
          //           if(propName != undefined && propName != null){
          //                if (value != undefined && value != null){
          //                     contacto[propName] = value;
          //                }
          //                else{
          //                     contacto[propName] = '';
          //                }
          //           }
          //           prevState.contacto = contacto;
          //           return prevState;
          //      });
          // };
          // const {contacto, disabled, showToast} = this.state;

          return (
               <Container>
               <div className="row">
               <div className="col-12 mt-4">
               <h1 className="text-center title mb-5">Contactanos</h1>
               <form id="contact-form" onSubmit={(evt) => contactanos(evt)}>
               <div className="messages"></div>
               <div className="controls">

               <div className="row">
               <div className="col-md-5 offset-md-1">
               <div className="form-group">
               <label htmlFor="form_name">Nombre *</label>
               <input id="form_name" type="text" name="name" className="form-control" placeholder="Por favor ingrese su nombre *" required="required" />
               <div className="help-block with-errors"></div>
               </div>
               </div>
               <div className="col-md-5">
               <div className="form-group">
               <label htmlFor="form_lastname">Apellido *</label>
               <input id="form_lastname" type="text" name="surname" className="form-control" placeholder="Por favor ingrese su apellido *" required="required" />
               <div className="help-block with-errors"></div>
               </div>
               </div>
               </div>
               <div className="row">
               <div className="col-md-5 offset-md-1">
               <div className="form-group">
               <label htmlFor="form_email">Email *</label>
               <input id="form_email" type="email" name="email" className="form-control" placeholder="Por favor ingrese su email *" required="required"/>
               <div className="help-block with-errors"></div>
               </div>
               </div>
               <div className="col-md-5">
               <div className="form-group">
               <label htmlFor="form_phone">Teléfono</label>
               <input id="form_phone" type="tel" name="phone" className="form-control" placeholder="Por favor ingrese su teléfono"/>
               <div className="help-block with-errors"></div>
               </div>
               </div>
               </div>
               <div className="row">
               <div className="col-md-10 offset-md-1">
               <div className="form-group">
               <label htmlFor="form_message">Mensaje *</label>
               <textarea id="form_message" name="message" className="form-control" placeholder="Ingrese su mensaje *" rows="4" required="required"></textarea>
               <div className="help-block with-errors"></div>
               </div>
               </div>
               <div className="col-md-12 text-center">
               <input type="submit" className="btn btn-success btn-send" value="Enviar mensaje" />
               </div>
               </div>
               </div>
               </form>
               </div>
               {
                    false == true &&
                    <Alert color="primary">
                    <i class="fa fa-envelope-o mr-2" aria-hidden="true"></i>Se ha enviado el mensaje exitosamente.
                    </Alert>
               }
               </div>
               </Container>
          );
     }
}
export default Contacto;
