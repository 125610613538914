import React from 'react';

// Assets
import logo from '../assets/img/site/logo.png';

// Componentes
import LoadingScreen from 'react-loading-screen';
// Componentes de tienda
// import ProductoBox from './Tienda/components/ProductoBox/ProductoBox';
import ProductoBox from './ProductoBox/ProductoBox';
import OfertasView from './Tienda/components/ProdOfertados/OfertasView';
import OfertaView from './Tienda/components/ProdOfertados/OfertaView';
import { Sugerencias, password_validator } from './Tienda/components/PasswordScheme/BaseScheme';

import DestacadosView from './Tienda/components/ProdDestacados/DestacadosView';
import DestacadoView from './Tienda/components/ProdDestacados/DestacadoView';
import CantidadSelectorView from './Tienda/components/CantidadSelector/CantidadSelectorView';
// Paginas
import ProductoView from './Tienda/views/Producto/ProductoView';
import ProductosView from './Tienda/views/Productos/ProductosView';

export const LoadingScreenProps = {
    spinnerColor: '#9ee5f8',
    logoSrc: logo,
}

export const View = {
    LoadingScreen: (props) => <LoadingScreen {...LoadingScreenProps} {...props} />,

    ProductoBox: ProductoBox,

    OfertasView: OfertasView,
    OfertaView: OfertaView,

    DestacadosView: DestacadosView,
    DestacadoView: DestacadoView,

    ProductosView: ProductosView,
    CantidadSelectorView: CantidadSelectorView,
    ProductoView: (props) => (<ProductoView {...props} magnifier={false} />),
}

export const GlobalConfigTienda = {
    NumeroDecimales: 0,
    logoSrc: logo,
    maxQuantity: 999,
    articulosFields: [
        "OfertaNxM_Precio", "Precio", "OfertaNxM_Cantidad",
        "OfertaPeriodo_FechaDesde", "OfertaPeriodo_FechaHasta",
        "PrecioSuperOferta", "DescuentoSuperOferta",

        "Talles", "Colores",
        "MargenRentabilidad",
        "MovimientosStock", 'IgnorarStock', 'PuedeComprar',

        "code", "Codigo", "Imagenes", "Titulo", "maxQuantity",

        "Rubro", "Rubro_id",
        "Linea", "Linea_id",
    ],

    passwordScheme : {Sugerencias, password_validator},
};
